// MAIN.JS
jQuery(function($) {

	/**********************************************/



	/**********************************************/

	// JQUERY.APPEAR
	if ($.fn.appear) {
		var appearSelectors = ".appear";
		$(appearSelectors).on('appear', function(event, $all_appeared_elements) {
			$.each($all_appeared_elements, function(e) {
				$(this).css("opacity", 1);
			});
		});
		$(appearSelectors).on('disappear', function(event, $all_disappeared_elements) {
			$.each($all_disappeared_elements, function(e) {
				// Activer ceci pour faire l'inverse lors que l'element sort de la partie visible
				// $(this).css("opacity", 0);
			});
		});
		$(window).load(function() {
			$(appearSelectors).appear({"force_process":true});
		});
	}

	/**********************************************/

	// JQUERY.MASKEDINPUT
	if ($.fn.mask) {
		$("input[type='text'].phone").mask("(999) 999-9999");
		$("input[type='text'].postalcode").mask("a9a9a9");
	}

	/**********************************************/

	// JQUERY.MAGNIFIC-POPUP
	if ($.fn.magnificPopup) {
		// image
		$('.image-popup').magnificPopup({type:'image'});
		$('.image-group-popup').magnificPopup({
			delegate: 'a',
			type: 'image'
		});
		// iframe
		$('.iframe-popup').magnificPopup({type:'iframe'});
		$('.iframe-group-popup').magnificPopup({
			delegate: 'a',
			type: 'iframe'
		});
		$('.open-product-link').magnificPopup({
				type:'inline',
				midClick: true,
				closeOnBgClick: true,
				gallery:{
						enabled:true
				}
		});
	}



	/**********************************************/

	// MENU
	var menu = ".menu-mobile";
	var menuToggle = ".menu-toggle";
	$(menuToggle).click(function(e) {
		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});

	/**********************************************/

	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}

	/**********************************************/

	// OWL.CAROUSEL2
if ($.fn.owlCarousel) {
		// Etre plus specific dans la classe des carousels dans le cas ou nous avons plusieur instance donc les settings differe!
		$('.owl-carousel').owlCarousel({
		    loop: true,
		    margin: 30,
			autoplay: true,
		    autoplayTimeout: 2000,
		    center: true,
		    slideBy: 2,
		    fluidSpeed: 250,
		    dots: true,
		    responsive:{
		        0:{
		            items:1
		        },
		        480:{
		            items:2
		        },
				768:{
					items:4
				},
		        1000:{
		            items:6
		        }
		    }
		});
	}

	/**********************************************/
	// PARALLAX
	if ($.fn.parallax) {
		$(".parallax-slow").parallax({
			speed: 0.1
		});
		$(".parallax").parallax({
			speed: 0.3
		});
		$(".parallax-fast").parallax({
			speed: 0.5
		});


		// PARALLAX
		// function parallax() {
		// 	var scroll = $(window).scrollTop();
		// 	//alert(scroll);
		// }

		// parallax();
		// $( window ).scroll(function() {
		// 	parallax();
		// });
		// $( window ).on('load',function() {
		// 	parallax();
		// });
	}

	/************************************************/
	/* menu hide and show when scrolled				*/
	/************************************************/
	var didScroll;
	var lastScrollTop = 0;
	var delta = 5;
	var navbarHeight = $(".menu-desktop").outerHeight();

	$(window).scroll( function(event) {
		didScroll = true;
	});
	setInterval(function() {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 250);

	function hasScrolled() {
		var st = $(this).scrollTop();

		// Make sure they scroll more than delta
	    if(Math.abs(lastScrollTop - st) <= delta)
	        return;
	    // If they scrolled down and are past the navbar, add class .nav-up.
	    // This is necessary so you never see what is "behind" the navbar.
	    if (st > lastScrollTop && st > navbarHeight){
	        // Scroll Down
	        $('.menu-desktop').removeClass('nav-down').addClass('nav-up');
	    } else {
	        // Scroll Up
	        if(st + $(window).height() < $(document).height()) {
	            $('.menu-desktop').removeClass('nav-up').addClass('nav-down');
			}
		}
		lastScrollTop = st;

		if (st > 0) {
			$('.module-menu').addClass('scrolled');
		}
		else {
			$('.module-menu').removeClass('scrolled');
		}
	}

	/************************************************/
	/* Ferme le popup si cliquer	 				*/
	/************************************************/
	$(".popup").click(function(e) {
	    $(".popup").fadeOut();
	});


	/************************************************/
	/* Montre les messages si présents 				*/
	/************************************************/
	$(document).ready(function () {
        if($('.errors').length) {
            $('html, body').animate({
                scrollTop: $('.errors').offset().top + 200
            }, 'slow');
        }
    });

    $(document).ready(function () {
		if($('.messages').length) {
			$('html, body').animate({
				scrollTop: $('.messages').offset().top + 200
			}, 'slow');
		}
    });


	/************************************************/
	/* Initialise le skrollr si utilisé				*/
	/************************************************/
	if ($.fn.skrollr) {
		skrollr.init({
			smoothScrolling: true,
			smoothScrollingDuration: 500,
			mobileDeceleration: 0.004,
			forceHeight: true,
			render: function(data) {
				//Debugging - Log the current scroll position.
				//console.log(data.curTop);
			}
		});
	}

});
